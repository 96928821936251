<section id="header" class="header w-100 sticky-top solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-8 mobile-width-pas-text">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-4 mobile-width-pas-button">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid shadowonscroll"
    [ngClass]="{ 'has-pas-header': isPASUser }"
  >
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl navwithbg">
            <a class="navbar-brand" href=""
              ><img class="mainlogo" src="assets/images/ract/ractlogo.svg"
            /></a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/ract/menu.png"
              /></span>
            </button>

            <div
              class="offcanvas offcanvas-start align-items-lg-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a class="navbar-brand" href=""
                    ><img
                      class="mainlogo"
                      src="assets/images/ract/ractlogo.svg"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item px-2">
                    <a class="nav-link" href="travel-alerts" target="_blank"
                      >Travel Alerts</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" href="faqs" target="_blank">FAQs</a>
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="{{ pdsLink }}"
                      >Product Disclosure Statement</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="/how-to-claim"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="cruise">Cruise</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>
