import { ExtraOptions, Routes } from '@angular/router';

import { SearchCountryComponent } from '../search-country/search-country.component';
import { StepOneComponent } from '../step-one-product-select/step-one.component';
import { StepThreeComponent } from '../step-three/step-three.component';
import { StepFourComponent } from '../step-four/step-four.component';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { StepTwoTravelerDetailsComponent } from '../step-two-traveler-details/step-two-traveler-details.component';
import { FaqsComponent } from '../staticpages/faqs/faqs.component';
import { HowToClaimComponent } from '../staticpages/how-to-claim/how-to-claim.component';
import { TravelAlertsComponent } from '../staticpages/travel-alerts/travel-alerts.component';
import { CovidFaqsComponent } from '../staticpages/covid-faqs/covid-faqs.component';
import { IsraelComponent } from '../staticpages/israel/israel.component';
import { IsraelFaqsComponent } from '../staticpages/israel-faqs/israel-faqs.component';
import { RefundsComponent } from '../staticpages/refunds/refunds.component';
import { TermsAndConditionsComponent } from '../staticpages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../staticpages/privacy-policy/privacy-policy.component';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';
import { CruiseComponent } from '../staticpages/cruise/cruise.component';
import { ReviewPolicyComponent } from '../staticpages/review-policy/review-policy.component';
import { ExpiredQuoteComponent } from '../expired-quote/expired-quote.component';

export const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top', // Add this line to automatically scroll to top on navigation
  anchorScrolling: 'enabled', // Optional, enables scrolling to anchor elements
  scrollOffset: [0, 64], // Optional, [x, y] - Adjust if you have a fixed header and the anchor is covered by the header
};

export const routes: Routes = [
  { path: '', component: SearchCountryComponent },
  { path: 'product', redirectTo: 'quotes-steps-one' },
  { path: 'quotes-steps-one', component: StepOneComponent },
  { path: 'quotes-steps-two', component: StepTwoTravelerDetailsComponent },
  { path: 'quotes-steps-three', component: StepThreeComponent },
  { path: 'quotes-steps-four', component: StepFourComponent },
  { path: 'payment-confirmation', component: PaymentConfirmationComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'quote-expired', component: ExpiredQuoteComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'how-to-claim', component: HowToClaimComponent },
  { path: 'travel-alerts', component: TravelAlertsComponent },
  { path: 'covid-faqs', component: CovidFaqsComponent },
  { path: 'israel', component: IsraelComponent },
  { path: 'israelfaqs', component: IsraelFaqsComponent },
  { path: 'refunds', component: RefundsComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'review-policy', component: ReviewPolicyComponent },
  { path: 'cruise', component: CruiseComponent },
  { path: '**', redirectTo: 'error' },
];
