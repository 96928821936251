<section class="header sticky-top solidheader solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-8 mobile-width-pas-text">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-4 mobile-width-pas-button">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid shadowonscroll"
    [ngClass]="{ 'has-pas-header': isPASUser }"
  >
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl navwithbg">
            <a class="navbar-brand" href="index.html"
              ><img class="mainlogo" src="assets/images/ract/ractlogo.svg"
            /></a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/ract/menu.png"
              /></span>
            </button>
            <div
              class="offcanvas offcanvas-start align-items-lg-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a class="navbar-brand" href=""
                    ><img
                      class="mainlogo"
                      src="assets/images/ract/ractlogo.svg"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      [ngClass]="{ active: activeHeader === 'TravelAlerts' }"
                      routerLink="/travel-alerts"
                      target="_blank"
                      >Travel Alerts</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      [ngClass]="{ active: activeHeader === 'FAQs' }"
                      routerLink="/faqs"
                      target="_blank"
                      >FAQs</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" target="_blank" href="{{ pdsLink }}">
                      Product Disclosure Statement</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      [ngClass]="{ active: activeHeader === 'HowToClaim' }"
                      target="_blank"
                      routerLink="/how-to-claim"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      target="_blank"
                      [ngClass]="{ active: activeHeader === 'Cruise' }"
                      routerLink="/cruise"
                      >Cruise</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="offcanvas-footer offcanvas-header border-top flex-column text-center"
              >
                <div class="col-12">
                  <a href="tel:131329"
                    ><i class="fa-solid fa-phone"></i>
                    <span class="fw-semibold">13 13 29</span></a
                  >
                </div>
                <div class="col-12"></div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

  <div class="container-fluid notificationss">
    <div class="row justify-content-center">
      <div class="col-xxl-11 col-xl-11 col-12 p-0">
        <div
          class="alert alert-primary customnotice text-center rounded-0 rounded-bottom alert-dismissible fade show z-index-1"
          role="alert"
        >
          <span class="d-sm-none"
            ><span class="specialbtn fw-semibold p-2 me-3 rounded"
              ><img
                class="warringicon"
                src="assets/images/ract/warning.png"
              />Important Notice</span
            >
            Travel Insurance Information for
            <a
              class="underline text-light"
              target="_blank"
              routerLink="/covid-faqs"
              >New COVID-19 FAQs</a
            >,
            <a class="underline text-light" target="_blank" routerLink="/israel"
              >Israel and the Occupied Palestinian Territories.</a
            >,
            <a
              class="underline text-light"
              target="_blank"
              routerLink="/israelfaqs"
              >FAQs for Israel and OPT</a
            ></span
          >
          <marquee class="d-md-none"
            ><span class="specialbtn fw-semibold p-2 me-3 rounded">
              <img class="warringicon" src="assets/images/ract/warning.png"
            /></span>
            Travel Insurance Information for
            <a
              class="underline text-light"
              target="_blank"
              routerLink="/covid-faqs"
              >New COVID-19 FAQs</a
            >,
            <a class="underline text-light" target="_blank" routerLink="/israel"
              >Israel and the Occupied Palestinian Territories.</a
            >,
            <a
              class="underline text-light"
              target="_blank"
              routerLink="/israelfaqs"
              >FAQs for Israel and OPT</a
            >
            </marquee
          >
        </div>
      </div>
    </div>
  </div>
</section>
