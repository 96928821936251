<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header [activeHeader]="'HowToClaim'"></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img src="assets/images/ract/page-header/how-to-claim.jpg" alt="" class="w-100 page-title-image" />
    <div class="page-header-title-caption">
      <h1>How to Claim</h1>

      <!-- <p>RACV makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>

  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <h3>In an emergency </h3>
            <p>When you are travelling, our emergency assistance teams can help you anywhere in the world by simply calling them. They are there for you, 24 hours a day, able to help with emergencies and advice. </p>
            <p>
              Our team, with over 50 years of experience, are only a phone call away. Call
              them on
              <a href="tel:+61280551627">+61 2 8055 1627</a>
              (the insurer will accept reverse charges from the overseas operator).
            </p>
            <h3>Call us</h3>
            <p>
              Please contact the claims team on 1300 406 752 and the insurer will happily
              take your details to lodge a claim and guide you through the claim process.
            </p>
            <h3>Lodge a claim online</h3>
            <p>
              The quickest way to lodge a claim on your RACT Travel Insurance policy is
              using the insurers online claim lodgement which is available 24/7. Follow
              these easy steps to lodge your claim online:
            </p>
            <ol>
              <li>
                You will first need to register by utilising the email address you
                used to purchase your policy
              </li>
              <li>
                Once registered you will receive an email to guide you to setup your
                password
              </li>
              <li>
                If you have previously registered a claim just login using your email
                and password
              </li>
              <li>
                Once you have logged in, complete your claim details by answering all
                sections
              </li>
              <li>
                Once submitted, your claim will be assessed within 10 working days and
                we will let you know if the insurer requires any further
                information/documentation from you.
              </li>
            </ol> 
            <div>
              <a
                href="https://ract-onlineclaim.tmnfatravelinsurance.com.au/Account/Register"
                target="_blank"
                class="btn btn-outline-info btn-lg px-5 me-2"
                >Register</a
              >
              <a
                href="https://ract-onlineclaim.tmnfatravelinsurance.com.au/Account/Login"
                target="_blank"
                class="btn btn-info btn-lg px-5"
                >Login</a
              >
            </div>
          </div>

          <div class="page-content-info">            
              <h2>Interpreting Services</h2>
              <p>
                We are pleased to offer assistance in the following ways if you need help
                understanding any aspect of your travel insurance policy or claim in your
                language.
              </p>
              <h2>Contact Translating and Interpreting Services</h2>
              <p>
                Translating and Interpreting Services (TIS) are available 24/7 and their
                interpreters speak over 160 languages. They're available over the phone and in
                person. Book an&nbsp;<a rel="noopener noreferrer" href="https://www.tisnational.gov.au/" target="_blank" >appointment with TIS</a>, or call them on 131 450. This is a free service.
              </p>

               
          </div>      
        </div>
      </div>
</div>

  </main>
  <app-racv-footer></app-racv-footer>
</div>