import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private config: any;

  constructor(private http: HttpClient) {}

  loadConfig() {
    return this.http.get(`${environment.baseAPIUrl}quote/configuration/kv/ract`).toPromise().then((data) => {
      this.config = data;
    });
  }

  getConfig() {
    return this.config;
  }
}

export class KvResponse
{
    addressKey: string = "";
    vrrUserName: string = "";
    vrrInternal: string = "";
    dmsKey: string = "";
    dmsOcmKey: string = "";
}