import { Component } from '@angular/core';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RouterLink } from '@angular/router';
import { RacvCommonHeaderComponent } from "../../../components/racv-common-header/racv-common-header.component";
import { RacvFooterComponent } from "../../../components/racv-footer/racv-footer.component";
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-israel-faqs',
    standalone: true,
    templateUrl: './israel-faqs.component.html',
    styleUrl: './israel-faqs.component.scss',
    imports: [StaticSearchComponent, RouterLink, RacvCommonHeaderComponent, RacvFooterComponent]
})
export class IsraelFaqsComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('israel-faqs');
  }
}
