import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonDataService } from './json-data.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WordingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private globalService: GlobalService, private jsonDataService: JsonDataService) {}

  getWordings(): Observable<any[]> {
    switch (environment.autoClub) {
      case 'RACT':
        return this.jsonDataService
          .getWordingJsonData()
          .pipe(map((validation) => validation.ractWordings));
      default:
        return this.jsonDataService.getWordingJsonData();
    }
  }

  getInformationTemplateWordings(): Observable<any[]> {
    switch (environment.autoClub) {
      case 'RACT':
        return this.jsonDataService
          .getInformationTepmlateJsonData()
          .pipe(map((validation) => validation.ractInformation));
      default:
        return this.jsonDataService.getInformationTepmlateJsonData();
    }
  }
}
